import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styles from './about-us.module.css'

const AboutUsPage = ({ data }) => {
  const { formatMessage } = useIntl()

  const hasSecondParagraph =
    formatMessage({ id: 'pages.aboutUs.p2' }) !== 'NULL'

  return (
    <Layout headerLinkColor="lg:text-purple">
      <SEO
        lang={formatMessage({ id: 'lang' })}
        title={formatMessage({ id: 'pages.aboutUs.title' })}
        description={formatMessage({ id: 'pages.aboutUs.description' })}
      />

      <section className="pt-48 pb-32 bg-blue-lightest text-purple-darker overflow-y-hidden">
        <div className="custom-container px-24">
          <h1 className="heading">
            {formatMessage({ id: 'pages.aboutUs.title' })}
          </h1>

          <p className="mt-12">{formatMessage({ id: 'pages.aboutUs.p1' })}</p>

          {hasSecondParagraph && (
            <p className="mt-4">{formatMessage({ id: 'pages.aboutUs.p2' })}</p>
          )}
        </div>

        <div className="relative custom-container custom-container--extralarge">
          <div className={styles.right}>
            <Img alt="Support 02" fluid={data.right.childImageSharp.fluid} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    left: file(relativePath: { eq: "about/about-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    right: file(relativePath: { eq: "about/about-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default AboutUsPage
